<template>
  <div class="gap-4 p-4">
    <Card style="max-width: calc(100vw - 3rem)">
      <template #title> Etablissement </template>
      <template #subtitle>
        Renseignez vos informations concernant votre établissement
      </template>
      <template #content>
        <form @submit.prevent="nextPage" id="infos">
          <div class="p-field p-fluid">
            <label>Etablissements</label>
            <MultiSelect
              :options="centersArray"
              :placeholder="pending ? 'Chargement...' : 'Selectionnez'"
              :loading="pending"
              optionLabel="name"
              optionValue="id"
              v-model="selectedCenters"
              display="chip"
            >
            </MultiSelect>
          </div>
          <div class="p-fluid p-field">
            <label>Poste occupé / Département</label>
            <Dropdown
              :options="roles"
              optionLabel="name"
              optionValue="value"
              v-model="selectedRole"
            ></Dropdown>
          </div>
        </form>
      </template>
      <template #footer>
        <hr />
        <div class="flex justify-content-between">
          <Button
            label="Précédent"
            class="p-button-sm p-button-text p-button-secondary"
            @click="prevPage"
          />
          <Button
            label="Suivant"
            class="p-button-sm"
            type="submit"
            form="infos"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { reactive, toRefs, computed, ref } from "vue";
import useDatabase from "../../../composables/useDatabase";

export default {
  props: ["roles"],
  setup(props, { emit }) {
    const pageIndex = 2;
    const prevPage = () =>
      emit("prev-page", {
        formData: { centers, role: selectedRole },
        pageIndex,
      });
    const nextPage = () =>
      emit("next-page", {
        formData: { centers, role: selectedRole },
        pageIndex,
      });

    const formData = reactive({
      role: undefined,
    });

    const selectedRole = ref();

    const { getAll } = useDatabase(`centers`);
    const { docs: centersArray, error, pending } = getAll();
    const centers = computed(() => {
      let result = {};
      selectedCenters.value.forEach((c) => {
        result[c] = true;
      });
      return result;
    });
    const selectedCenters = ref([]);

    return {
      prevPage,
      nextPage,
      ...toRefs(formData),
      centersArray,
      selectedCenters,
      selectedRole,
      centers,
      error,
      pending,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-field {
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
